

import {Component, Vue} from 'vue-property-decorator';
import CompanyForm from '@/components/Company/CompanyForm.vue';
import CompanySettingsForm from '@/components/Company/CompanySettingsForm.vue';
import CompanyUsers from '@/components/Company/CompanyUsers.vue';
import ICompanyInfo from '@/types/ICompanyInfo';
import CompanyService from '@/services/CompanyService';
import {resolveError} from '@/utils/notifications';
import {IUsersList} from '@/types/IUsersList';
import UserFactory from '@/factories/UserFactory';
import DataTableFactory from '@/factories/DataTableFactory';
import {IPagination} from '@/types/IPagination';
import {isPermission} from '@/utils/perrmissions';

@Component({
  name: 'Company',
  components: {
    CompanySettingsForm,
    CompanyForm,
    CompanyUsers,
  },
})

export default class Company extends Vue {
  companyData: ICompanyInfo | null = null;
  companyUsersList: IUsersList = UserFactory.toUsersList({});
  companyUsersLoading: boolean = true;
  params: IPagination = DataTableFactory.toPagination({});

  constructor() {
    super();
    this.fetchData();
  }

  created() {
    this.fetchUsers();
  }

  async fetchData() {
    try {
      this.companyData = await CompanyService.getCompanyInfo();
    } catch (e) {
      resolveError(e, 'fetch_data');
    }
  }

  async fetchUsers() {
    this.companyUsersLoading = true;
    try {
      this.companyUsersList = this.parseUsersData(await CompanyService.getCompanyUsers(this.params));
      this.params.totalElements = this.companyUsersList.totalElements;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.companyUsersLoading = false;
    }
  }

  paginationChanged(pagination: IPagination) {
    if (this.params.page === pagination.page && this.params.size === pagination.itemsPerPage) {
      return;
    }
    this.params.page = pagination.page || 1;
    this.params.size = pagination.itemsPerPage || 10;

    this.fetchUsers();
  }

  parseUsersData(userData: IUsersList) {
    userData.content.forEach((element) => {
      element.loading = false;
      element.menu = false;
    });

    return userData;
  }

  isPermission(permissions: string[]): boolean {
    return isPermission(permissions);
  }
}
