

import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import IUser from '@/types/IUser';
import UserFactory from '@/factories/UserFactory';
import {email, password, required} from '@/utils/validationRules';
import CompanyService from '@/services/CompanyService';
import {resolveError} from '@/utils/notifications';
import {IUserEditForm} from '@/types/IUserEditForm';

@Component({name: 'CreateOrEditCompanyUser'})
export default class CreateOrEditCompanyUser extends Vue {
  dialog: boolean = false;
  show: boolean = false;
  loading: boolean = false;
  form: IUserEditForm = UserFactory.toCreateUser({});
  rules = {
    required: [required],
    mail: [required, email],
    password: [password, required],
  };

  @Prop()
  data!: IUser;

  @Prop()
  create!: boolean;

  @Prop()
  roles!: string[];

  @Watch('dialog')
  prepareData(val: boolean) {
    // @ts-ignore
    if (val) {
      this.form = this.create ? UserFactory.toCreateUser({}) : UserFactory.toEditUser(this.data);
    } else {
      this.dialog = false;
    }
  }

  toggleVisibility() {
    this.dialog = false;
    // @ts-ignore
    this.$refs.userForm.resetValidation();
  }

  save() {
    // @ts-ignore
    if (this.$refs.userForm.validate()) {
      if (this.create) {
        this.createUser();
      } else if (!this.create && this.form.id) {
        this.updateUser();
      }
    }
  }

  async createUser() {
    this.loading = true;
    try {
      await CompanyService.createCompanyUser(UserFactory.toCreateUser(this.form));
      this.update();
      this.toggleVisibility();
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  async updateUser() {
    this.loading = true;
    try {
      await CompanyService.updateCompanyUserRole(this.form);
      this.update();
      this.toggleVisibility();
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  update() {
    this.$emit('update');
  }
};
